/**
 * border around store item with constant screen width
 */
AFRAME.registerComponent("item-border-line", {
    schema: {
        borderHalfSize: {type: "vec2", default: {x: 0.6, y: 0.6}},

        lineWidth: {default: 0.02},

        cornerRadius: {default: 0.05},
        /** distance between curve start and bezier control points */
        cornerControlPointDistance: {default: 0.55228},
        cornerSize: {default: 0.4},
        cornerNumPoints: {default: 8},

        color: {type: "color", default: "#fff"},
        opacity: {type: "number", default: 1},
    },

    init: function () {
        this.bindMethods();

        this.createCorners();
    },

    update: function (oldData) {
        const modifiedData = AFRAME.utils.diff(oldData, this.data);

        for (const modifiedKey in modifiedData) {
            switch (modifiedKey) {
                case "lineWidth":
                    for (const cornerEl of this.allCornersEl)
                        cornerEl.setAttribute("meshline", "lineWidth", this.data.lineWidth);
                    break;

                case "color":
                    for (const cornerEl of this.allCornersEl)
                        cornerEl.setAttribute("border-corner", "color", this.data.color);
                    break;

                case "opacity":
                    for (const cornerEl of this.allCornersEl)
                        cornerEl.setAttribute("border-corner", "opacity", this.data.opacity);
            }
        }
    },

    createCorners: function () {
        this.allCornersEl = new Array(4);

        for (let i = 0; i < 4; ++i) {
            this.allCornersEl[i] = document.createElement("a-entity");

            this.allCornersEl[i].setAttribute("border-corner", {
                curveNumPoints: this.data.cornerNumPoints,
                lineBaseWidth: this.data.lineBaseWidth,
                updateDeltaWidth: this.data.updateDeltaWidth,
                color: this.data.color,
            });
        }
        this.topRightCornerEl = this.allCornersEl[0];
        this.bottomRightCornerEl = this.allCornersEl[1];
        this.bottomLeftCornerEl = this.allCornersEl[2];
        this.topLeftCornerEl = this.allCornersEl[3];

        const cornerSize = this.data.cornerSize;
        const cornerRadius = this.data.cornerRadius;
        const ctrlPDist = this.data.cornerControlPointDistance;
        const ctrlPDistToCorner = (1 - ctrlPDist) * cornerRadius;

        const setPointsAttributes = (
            cornerEl,
            startPoint,
            curveStartPoint,
            curveControlPoint0,
            curveControlPoint1,
            curveEndPoint,
            endPoint
        ) => {
            cornerEl.setAttribute("border-corner", {
                startPoint: startPoint,
                curveStartPoint: curveStartPoint,
                curveControlPoint0: curveControlPoint0,
                curveControlPoint1: curveControlPoint1,
                curveEndPoint: curveEndPoint,
                endPoint: endPoint,
            });
        };
        const d0 = cornerSize;
        const d1 = cornerRadius;
        const d2 = ctrlPDistToCorner;

        {
            // top right
            const pos = this.data.borderHalfSize;
            setPointsAttributes(
                this.topRightCornerEl,
                new THREE.Vector2(pos.x - d0, pos.y),
                new THREE.Vector2(pos.x - d1, pos.y),
                new THREE.Vector2(pos.x - d2, pos.y),
                new THREE.Vector2(pos.x, pos.y - d2),
                new THREE.Vector2(pos.x, pos.y - d1),
                new THREE.Vector2(pos.x, pos.y - d0)
            );
        }

        {
            // bottom right
            const pos = new THREE.Vector2(this.data.borderHalfSize.x, -this.data.borderHalfSize.y);
            setPointsAttributes(
                this.bottomRightCornerEl,
                new THREE.Vector2(pos.x, pos.y + d0),
                new THREE.Vector2(pos.x, pos.y + d1),
                new THREE.Vector2(pos.x, pos.y + d2),
                new THREE.Vector2(pos.x - d2, pos.y),
                new THREE.Vector2(pos.x - d1, pos.y),
                new THREE.Vector2(pos.x - d0, pos.y)
            );
        }

        {
            // bottom left
            const pos = new THREE.Vector2(-this.data.borderHalfSize.x, -this.data.borderHalfSize.y);
            setPointsAttributes(
                this.bottomLeftCornerEl,
                new THREE.Vector2(pos.x + d0, pos.y),
                new THREE.Vector2(pos.x + d1, pos.y),
                new THREE.Vector2(pos.x + d2, pos.y),
                new THREE.Vector2(pos.x, pos.y + d2),
                new THREE.Vector2(pos.x, pos.y + d1),
                new THREE.Vector2(pos.x, pos.y + d0)
            );
        }

        {
            // top left
            const pos = new THREE.Vector2(-this.data.borderHalfSize.x, this.data.borderHalfSize.y);
            setPointsAttributes(
                this.topLeftCornerEl,
                new THREE.Vector2(pos.x, pos.y - d0),
                new THREE.Vector2(pos.x, pos.y - d1),
                new THREE.Vector2(pos.x, pos.y - d2),
                new THREE.Vector2(pos.x + d2, pos.y),
                new THREE.Vector2(pos.x + d1, pos.y),
                new THREE.Vector2(pos.x + d0, pos.y)
            );
        }

        for (let i = 0; i < 4; ++i) {
            this.el.appendChild(this.allCornersEl[i]);
        }
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {},
});
